.main{
    padding: 5rem 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about{
    width: 95%;
    height: 28rem;
    background-image: linear-gradient(to left, #062348, #278EA5 );
    align-items: center;
    border-radius: 280px 20px 280px 20px;
    backdrop-filter: blur(100rem);
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-left: 50px;
    padding-top: 80px;
    border: 2px solid #21E6C1;
}
.about h1{
    font-size: 2.5rem;
}
.about-img{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    object-fit: fill;
    height: 20rem;
    width: 20rem;
    margin-right: 4rem;
}
.about-img img{
    padding: 0;
    margin: 0;
    height: 20rem;
    width: 28rem;
    object-fit: cover;
}
.about h6{
    font-size: 1rem;
    font-weight: 100;
    padding-bottom: 3rem;
}
.about-text{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35rem;
    flex-direction: column;
}
.about-text button{
    padding:  1rem;
    border-radius: 10px;
    color: black;
    background-color: #21E6C1;
    border: none;
    cursor: pointer;
}
.heading{
    display: flex;
    gap: 30px;
    
}
@media (max-width:500px) {
    .main{
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    .about{
        width: 95%;
        height: 48rem;
        background-image: linear-gradient(to left, #062348, #278EA5 );
        align-items: center;
        /* border-radius: 280px 20px 280px 20px; */
        border-radius: 20px;
        backdrop-filter: blur(100rem);
        display: flex;
        flex-direction: column;
        padding: 30px;
        padding-left: 40px;
        padding-top: 80px;
        border: 2px solid #21E6C1;
        margin-top: 1rem;

    
    }
   
    .about-img{
        display: flex;
        display: none;
        justify-content: center;
        align-items: center;
        padding-bottom: 3rem;
    }
    .about-img img{
        padding: 0;
        margin: 0;
        height: 20rem;
        width: 28rem;
        object-fit: cover;
        display: none;
    }
    .about h6{
        font-size: 1rem;
        font-weight: 100;
        padding-bottom: 3rem;
    }
    .about-text{
        display: flex;
        justify-content: center;
        align-items: center;
        /* height: 30rem; */
        width: 15rem;
        text-align: justify;
        margin-bottom: 1rem;
    }

}

@media (min-width:350px) and (max-width:500px) {
    .main{
        display: flex;
        width: 100vw;
        justify-content: center;
        align-items: center;
        margin: 0;
        
    }
    .about{
        width: 18rem;
        height: 38rem;
        background-image: linear-gradient(to left, #062348, #278EA5 );
        align-items: center;
        border-radius: 20px;
        backdrop-filter: blur(100rem);
        display: flex;
        flex-direction: column;
        padding: 0.1rem;
        border: 2px solid #21E6C1;
    }
        .about h2{
            margin-bottom: 2rem;
            font-size: 2.5rem;
        }
        .about-img{
            display: flex;
            display: none;
            justify-content: center;
            align-items: center;
            padding-bottom: 3rem;
        }
        .about-img img{
            padding: 0;
            margin: 0;
            height: 20rem;
            width: 28rem;
            object-fit: cover;
            display: none;
        }
        .about h6{
            font-size: 1rem;
            font-weight: 100;
            padding-bottom: 3rem;
        }
        .about-text {
            display: flex;
            justify-content: center;
            align-items: center;
           
            width: 15rem;
            text-align: justify;
            font-size: 1rem;
        }
        .heading{
            display: flex;
            padding: 0;
            /* gap: 0.5rem; */
        
        }
        .about-text h6{
            font-size: smaller;
        }
    
    }