.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  width: 100%;
  max-width: 800px;
  padding: 6rem;
  /* align-content: space-around; */
  border: 1px solid #21E6C1;
  border-radius: 280px 20px 280px 20px;
  backdrop-filter: blur(100rem);
  color: #fff;
  background-image: linear-gradient(to left, #062348, #278EA5);
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.description h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.exhibition-tiles {
  display: grid;
  justify-content:space-between;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  overflow-x: auto;
  padding: 10px;
  gap: 20px;
  scroll-snap-type: x mandatory;
}

.exhibition-tiles::-webkit-scrollbar {
  height: 8px;
}

.exhibition-tiles::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.exhibition-tiles::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .description {
    padding: 1.5rem;
    border-radius: 50px;
    background-image: linear-gradient(to right, #062348, #278EA5);
  }

  .description h1 {
    font-size: 3rem;
  }

  .exhibition-tiles {
      display: grid;
  grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .description {
    padding: 1.5rem;
    border-radius: 30px;
    width: 90%;
    background-image: linear-gradient(to bottom, #062348, #278EA5);
  }

  .description h1 {
    font-size: 2rem;
  }

  .exhibition-tiles {
      display: grid;
  grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .main {
    padding: 10px;
  }
}
