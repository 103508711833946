.artpiece-container {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap; 
  height: 100%;
  padding: 10px; 
}

.artpiece-video {
  object-fit:contain;
  display: flex;
  height:auto;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 1px solid #21E6C1;
  cursor: pointer;
}

.artpiece-card {
  object-fit:fill;
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 1px solid #21E6C1;
  cursor: pointer;
}

.artpiece-card img {
  width: 100%;
  height: 400px;
  object-fit:contain;
  border-radius: 5px;
  margin-bottom: 10px;
}

.artpiece-card h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #ffffff;
  text-align: center;
}

.artpiece-card p a{
  font-size: 14px;
  color: #39a3ff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.artpiece-card p a:hover{
  color: #ffffff;
}

.artpiece-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Close button for the modal */
.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* Modal content (expanded image) */
.modal-content {
  margin: auto;
  display: block;
  max-width: 90%;
  max-height: 80vh;
  width: auto;
  height: auto;
}

/* Caption for the expanded image */
.modal-caption {
  text-align: center;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 18px;
}



/* Responsive styles */
@media (max-width: 768px) {
  .artpiece-card {
    max-width: 200px;
    padding: 10px;
  }

  .artpiece-card h3 {
    font-size: 16px;
  }

  .artpiece-card p {
    font-size: 12px;
  }

  .artpiece-video {
     object-fit:fill;

  }
    .artpiece-card img {
    height: auto;
  }

  .modal-content {
    max-width: 85%;
    max-height: 75vh;
  }
}

@media (max-width: 480px) {
  .artpiece-card {
    max-width: 150px;
    padding: 8px;
  }

  .artpiece-card h3 {
    font-size: 14px;
  }

  .artpiece-card p {
    font-size: 10px;
  }

  .artpiece-video {
      object-fit:fill;
  }

  .artpiece-card img {
    height: auto;
  }

  .modal-content {
    max-width: 80%;
    max-height: 70vh;
  }
}
