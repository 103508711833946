#members h2{
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 2rem;
    font-size: 2rem;
}
.group{
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    margin-bottom: 3rem;
}
.member{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px #21E6C1 solid;
    padding: 1rem;
    border-radius: 2rem;

}
.member img{
    height: 250px;
    width: 200px;
    margin: 10px 30px;
    border-radius: 80px;
    object-fit: cover;
}
.member-info{
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.member-socials a{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    color: var(--color-primary);
    font-weight: 100;
}
.member-socials{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.member-socials a:hover{
    color: var(--color-secondary);
}
.member-name h5{
    font-weight: 200;
    font-size: 1rem;
}
.member-name h6{
    font-weight: 100;
    font-size: 0.8rem;
}
@media (max-width:350px) {
    .group{
        display: flex;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 3rem;
    }
}
@media (min-width:351px) and (max-width:500px){
    .group{
        display: flex;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 3rem;
    }
}