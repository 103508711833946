#events h2 {
  text-align: center;
  padding-top: 5rem;
  font-size: 2rem;
}
.QR img{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  height: 10rem;
  width: 10rem;
}
.dandiya{
  background-color:#4CC9F0;
  border: none;
  font-weight: 600;
  height: 2rem;
  width: 10rem;
  margin: 0;
  padding: 0;
}
.options {
  display: flex;
  /* border: 2px solid black; */
  width: 100%;
  height: 2rem;
  /* font-size: 12px; */
}
#cb{
  margin: 0;
  height: 1rem;
  margin-left: -6rem;
  margin-top: 0.3rem;
}
.QR{
  display:flex ;
  justify-content: center;
  align-items: center;
}
.events-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  gap: 4rem;
  padding: 2rem;
}
.event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 26rem;
  text-align: center;
  border-radius: 1rem;
  /* background-color: var(--color-primary); */
  border: 1px solid var(--color-primary);
  transition: all 0.3s ease-in-out;
}
.events-container .event1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:80%;
  text-align: center;
  border-radius: 1rem;
  /* background-color: var(--color-primary); */
  border: 1px solid var(--color-primary);
  transition: all 0.3s ease-in-out;
}
/* .event:hover {
  translate: -10px -10px;
  box-shadow: 10px 10px 0 var(--color-primary);
} */
.hidden {
  display: none;
}
.event-form,
.event-descp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40rem;
  height: 45rem;
  text-align: center;
  margin-top: 5rem;
  /* overflow-y:scroll ; */
  /* padding-left: 2rem;
  padding-right: 2rem; */
}
.event-image img {
  width: 20rem;
  height: 12rem;
  /* object-fit: cover; */
  margin: 2rem 2rem 0rem 2rem;
  border-radius: 0.7rem;
}
.event-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.event-info > h1 {
  text-align: left;
  margin-bottom: 0.5rem;
  width: 100%;
  font-weight: 400;
  font-size: 2rem;
}
.event-info > p {
  text-align: left;
  width: 20rem;
  margin-bottom: 1rem;
  font-weight: 200;
  font-size: 1rem;
}
.btn {
  margin: 1rem 1rem 1rem 0;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--color-bg);
  font-size: 1rem;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid var(--color-primary);
}
.btn:hover {
  background-color: var(--color-bg);
  border: 2px solid white;
  font-weight: bold;
  color: white;
}
.modal {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  border-width: 5rem;

}

.modal-content {
  width: 100%;
  background: linear-gradient(to right,#800080, #3535ff);
  padding: 20px;
  color: rgb(255, 255, 255);
  font-weight: 400;
  overflow-y: auto;
    border-radius: 10px;
      justify-content: center;
  align-items: center;

}
.modal-content img {
  width: 40rem;
  height: 25rem;
  margin: 1rem;
  border-radius: 0.7rem;
}



.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}

.buttons {
  display: flex;
  align-items: space-between;
  width: 100%;
  margin-bottom: 0.3 rem;
}

@media (min-width:350px) and (max-width:500px) {
  #events h2 {
    text-align: center;
    padding-top: 5rem;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .events-container {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    /* flex-wrap: wrap; */
    margin: 0;
    gap: 2rem;
    padding: 0rem;
    margin: 0rem;
  }
  .event {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    text-align: center;
    border-radius: 1rem;
    border: 1px solid var(--color-primary);
    transition: all 0.3s ease-in-out;
    height: 25rem;
  }
  .hidden {
    display: none;
  }
  .event-form,
  .event-descp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
    text-align: center;
    margin: 0px;
  }
  .event-image img {
    width: 15rem;
    height: 12rem;
    margin: 1rem 1rem 0rem 1rem;
    border-radius: 0.7rem;
  }
  .event-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .event-info > h1 {
    text-align: center;
    margin-bottom: 0.1rem;
    width: 100%;
    margin-top: 0.2rem;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .event-info > p {
    text-align: justify;
    width: 12rem;
    margin-bottom: 0.1rem;
    font-weight: 200;
    font-size: 0.8rem;
  }
  .btn {
    margin-left: auto;
    margin-right: auto;
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    background-color: var(--color-primary);
    color: var(--color-bg);
    font-size: 1rem;
    cursor: pointer;
    box-sizing: border-box;
    border: 2px solid var(--color-primary);
    margin-top: 0.6rem;
    margin-bottom: 0.1rem;
  }
  .btn:hover {
    background-color: var(--color-bg);
    border: 2px solid white;
    font-weight: bold;
    color: white;
  }
  .modal {
    width: 10rem;
    height: 20rem;
  }

  .modal-content {  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y:auto;
  background-color: #7c48dc;
  color: rgb(255, 255, 255);
    border-radius: 10px;
    font-weight: 300;

  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover {
    color: black;
  }
  
  .buttons {
    display: flex;
    align-items: space-between;
    width: 100%;
    margin-bottom: 0.3 rem;
    gap: 0.3rem;
  }
  .event-image1{
    display: none;
  }
}




.modal1 {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
}

/* Close button for the modal */
.close1 {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.close1:hover,
.close1:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* Modal content (expanded image) */
.modal1-content {
  margin: auto;
  display: block;
  max-width: 90%;
  max-height: 80vh;
  width: auto;
  height: auto;
  border-radius: 0.7rem;
  align-items: center;
  justify-content: center;

}

/* Caption for the expanded image */
.modal1-caption {
  text-align: center;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 18px;
}



/* Responsive styles */
@media (max-width: 768px) {

  .modal1-content {
    max-width: 85%;
    max-height: 75vh;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .modal1-content {
    max-width: 80%;
    max-height: 70vh;
    align-items: center;
    justify-content: center;
  }
}
