@import url('https://fonts.googleapis.com/css2?family=Protest+Riot&display=swap');

.new {
    background-image: url('../../assets/web_page.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center -120px;
    overflow: hidden;
  }
   
  .landing {
    height: 100vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 0;
    margin-left: 5rem;
    margin-bottom: 1rem;
  }
  .landing h1{
    margin-bottom: 25rem;
    font-size: 6rem;
    font-weight: bolder;
      font-family: "Protest Riot", sans-serif;
  font-weight: 400;
  font-style: normal;
  }
.landing button{
  margin-left: -48rem;
  margin-top: -12rem;
  padding: 1rem 1rem;
  border-radius: 5px;
  border: none;
  background-color: #21E6C1;
  color: white;
  font-size: 1.5rem;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.3s;
}

  @media (max-width:350px) {
    .landing {
      height: 100vh;
      display: flex;
      align-items: center;
      margin: 0;
      margin-left: 5rem;
    }
    .landing h1{
      margin-bottom: 17rem;
      font-size: 3rem;
      font-weight: bolder;
      margin-left: auto;
      margin-right: 1rem;
      margin-left: 11rem;
      padding: 0;
    }
      
      .landing button{
        padding: 0.5rem 0.5rem;
        border-radius: 5px;
        border: none;
        background-color: #21E6C1;
        color: white;
        font-weight: bolder;
        cursor: pointer;
        transition: 0.3s;
      }
    }

  @media (min-width:350px) and (max-width:500px) {
    .landing {
      height: 100vh;
      display: flex;
      align-items: center;
      margin: 0;
    }
    .landing h1{
      margin-bottom: 17rem;
      font-size: 3rem;
      font-weight: bolder;
      margin-left: 10rem;
      width: fit-content;
      padding: 0;
    } 

    .landing button{
      margin-left: -20rem;
      margin-top: -40rem;
      padding: 0.5rem 0.5rem;
      height: 3rem;
      border-radius: 5px;
      border: none;
      background-color: #21E6C1;
      color: white;
      font-size: 1rem;
      font-weight: bolder;
      cursor: pointer;
      transition: 0.3s;
    }
    .new{
      
      background-image: url(../../assets/responsive_wallpaper.png);
      width:100vw;
    }
  }
  
