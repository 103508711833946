body{
    overflow-x: hidden;
}
.head{
    width: 100%;
    height: fit-content;
    background: rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    z-index:2;
 
}
.image{
    width: 100px;
}
.sections{
    display: flex;
    gap: 1.5rem;
    margin-right: 6rem;
    margin-left: 20rem;
}
.sections a{
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}
.sections a:hover{
    color: var(--color-primary);
}
.logo{
    height: 3rem;
    display: flex;
    width: 3rem;
    justify-content: center;
    align-items: center;
}
img{
    background: none;
    height: 4rem;
    width: 4rem;
    /* margin-right: 20rem; */
    margin-top: 0.5rem;
}
@media (max-width:350px){
    .head{
        width: 100%;
        height: fit-content;
        background: rgba(255, 255, 255, 0.15);
        display: flex;
        flex-direction: column;
        z-index:2;
     
    }
    .sections{
        display: flex;
        gap: 10px;
        margin: 0px 5px 0px 0px;
        font-size:x-small;
    }
    .sections a{
        color: white;
        font-size: 0.8rem;
        font-weight: 500;
    }
    .sections a:hover{
        color: var(--color-primary);
    }
}
@media (min-width:350px) and (max-width:500px) {
    
    .head{
        width: 100%;
        height: fit-content;
        background: rgba(255, 255, 255, 0.15);
        display: flex;
        flex-direction: column;
        padding: 0;
        padding-bottom: 1rem;
        z-index:2;
     
    }
    .sections{
        display: flex;
        gap:0.5rem;
        font-size:x-small;
        align-items: flex-start;
        margin: 0;
        width: 90vw;
        padding-left: 1rem;
    }
    .sections a{
        color: white;
        font-size: 0.9rem;
        font-weight: 500;
    }
    .sections a:hover{
        color: var(--color-primary);
    }
}